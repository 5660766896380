.modal {
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.4);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    pointer-events: none;
    transition: 0.5s;
}

.modal.active {
    pointer-events: all;
    opacity: 1;
}

.modal__content {
    display: flex;
    flex-direction: column;
    align-content: center;
    padding: 20px;
    border-radius: 12px;
    background-color: #fff;
    width: 30vw;
    transform: scale(0.5);
    transition: 0.4s all;

}

.modal__content.active {
    transform: scale(1)
}

.modal p {
    text-align: center;
}