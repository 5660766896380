html,
body {
    height: 100%;
    margin: 0;
    padding: 0;
}

p {
    margin: 5px 0 5px 0;
}

body {
    background-color: #f6f6f6;
}

.header {
    background-color: black;
    color: #fff;
    padding: 20px 40px;
    font-family: 'Cormorant Garamond', serif;
    font-size: 48px;
    display: flex;
    justify-content: space-evenly;
}

.page {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    margin: 0 auto;
    max-width: 1400px;
    min-width: 1000px;
    min-height: 100vh;
    font-family: 'Source Code Pro', monospace;
}

.footer {

    background-color: black;
    margin: 0px;
    color: #fff;
}

.footer p {
    margin: 5px auto;
    text-align: center;
}

.trizub {
    width: 50px;
    height: 100px;
    margin: auto;
}

#root {
    flex: 1;
    padding: 20px;
    display: flex;
    flex-direction: column;
}

ul {
    list-style: none;
}

.orderMenu__wrapper {
    display: flex;
    column-gap: 40px;
    background-color: #e7eef3;
    padding: 20px;
}

.orderMenu__sectionWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.orderMenu__inputWrapper {
    min-width: 200px;
}


.orderMenu__inputsSection {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

}

.tripNumberBasis {
    display: flex;
    column-gap: 40px;
}

.orderMenu__tripShortlist {
    display: flex;
    flex-direction: column;
}

.orderMenu__tripShortlistWrapper {
    display: flex;
    flex-direction: column;
    border-left: #f6f6f6 1px solid;
    padding-left: 20px;
    justify-content: space-between;

}

.orderMenu__tripShortlist ul {
    line-height: 2rem;
    padding: 0;

}

.tripLocationCompany {
    display: flex;
    column-gap: 40px;
}

.tripLocationCompany {
    display: flex;

}

.tripLocationCompany div {
    display: flex;
    align-self: flex-start;
}

.tripDate {
    display: flex;
    column-gap: 40px;
}

.tripMenu {
    padding: 20px;
    background-color: #e6ebe6;
    display: flex;
    flex-direction: column;
}




.btn__wrapper {
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;




}

.btn__text {
    text-align: center;
    cursor: pointer;
    user-select: none;
    padding: 10px 20px;
    border: 1px solid #e7eef3;
    background-color: black;
    color: white;
    margin: 5px;
    transition: all 0.3s;
}

/* .btn__text.disabled {
    background-color: rgb(175, 175, 175);
    pointer-events: none;
}

.btn__text.disabled:hover {
    background-color: rgb(175, 175, 175);
    pointer-events: none;
} */

.red {
    border-color: rgb(251, 103, 103);
    color: rgb(251, 103, 103);
}

.btn__text:hover {
    cursor: pointer;
    border: 1px solid black;
    background-color: white;
    color: black;
}





.navigationBtn {
    margin-left: 10px;
    margin-top: 14px;
}

.navigationBtn p {
    display: inline;
    cursor: pointer;
    padding: 10px 20px;
    border: 1px solid #e7eef3;
    background-color: black;
    color: white;
    transition: all 0.3s;
    user-select: none;
}

.navigationBtn p:hover {
    cursor: pointer;
    border: 1px solid black;
    background-color: white;
    color: black;

}

.tripMenuSections {
    display: flex;
    justify-content: space-between;
}

.tripPurpose {
    display: flex;
    flex-direction: column;
    margin: 20px;
}

.tripPurposeWrapper {
    width: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.tripNumberBasisDateWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 60px;
}




.deleteBtn {
    display: inline;
    cursor: pointer;
    padding: 8px 20px;
    border: 1px solid #e7eef3;
    background-color: black;
    color: white;
    transition: all 0.3s;
    user-select: none;
}

.deleteBtn:hover {
    cursor: pointer;
    border: 1px solid black;
    background-color: white;
    color: black;

}

.oldOrders__wrapper {
    margin-bottom: 20px;

}

.oldOrders__container {
    padding: 20px;
    background-color: #e7eef3;
    max-height: 350px;
    overflow-y: scroll;
    overflow-x: hidden;
    margin-bottom: 40px;
}

.oldOrders__orderCaption {
    font-weight: bold;
}

.oldOrders__orderCaption span {
    font-weight: 400;
    margin-right: 20px;
}

.oldOrders__orderSection {
    margin: 10px 0 10px 0;
    padding: 10px 30px 10px 30px;
    display: flex;
    background-color: #e7eef3;
    justify-content: space-between;
    transition: all 0.5s;

}

.oldOrders__orderSection:hover {

    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.selectedOrder {
    background-color: #ccd2d7;
}

.oldOrders__tripSection {
    margin: 0 0 0 20px;
}


.icon_wrapper {
    display: flex;
    cursor: pointer;
}

.icon {
    margin: 6px;
}

.icon_wrapper:hover {
    color: black;
    transition: all 0.3s;
}

.icon_wrapper:hover {
    color: #6895bc;
}

.tripShortlist__trip {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 5px;
}

.tripShortlist__trip svg {
    margin-top: 12px;
}

.modalChildren__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.modalChildren__btns {
    display: flex;
}